import { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Masonry from '@mui/lab/Masonry';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment-timezone';
import MuiAlert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Cookies from 'js-cookie';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import StarIcon from '@mui/icons-material/Star';
import MicIcon from '@mui/icons-material/Mic';
import TwitterIcon from '@mui/icons-material/Twitter';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: '#333',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

// function formatDate(date, timezone) {
//     console.log(date * 1000, timezone);
//     return moment(date * 1000).local().format("YYYY-MM-DD HH:SS a");

//     // return moment.tz(date * 1000, timezone).format("YYYY-MM-DD HH:SS a");
// }

const prepareDate = (date) => {
    return moment(date.toDate()).valueOf();
}

const formatDate = (date) => {
    let formatted = moment.unix(date / 1000);
    return moment(formatted).local().format('LLLL');
}

function Space({ space, twitterUser, isAdmin, handleParticipate, handleUnParticipate, approveSpeaker, denySpeaker, refreshSpaces }) {
    const [speakerOpen, setSpeakerOpen] = useState(false);
    const [cancelOpen, setCancelOpen] = useState(false);

    const handleSpeakerClose = () => {
        setSpeakerOpen(false);
    }
    
    console.log(space);
    const isParticipating = () => {
        if (!twitterUser) {
            return false;
        }

        for (let i = 0; i < space.participants.length; i++) {
            if (space.participants[i].userId === twitterUser.id) {
                return true;
            }
        }

        return false;
    }

    const isApproved = () => {
        if (!twitterUser) {
            return false;
        }

        for (let i = 0; i < space.participants.length; i++) {
            if (space.participants[i].userId === twitterUser.id && space.participants[i].isApproved) {
                return true;
            }
        }

        return false;
    }

    const isPending = () => {
        if (!twitterUser) {
            return false;
        }

        for (let i = 0; i < space.participants.length; i++) {
            if (space.participants[i].userId === twitterUser.id && space.participants[i].pending) {
                return true;
            }
        }

        return false;
    }


    const approvedSpeakers = (participants) => {
        let num = 0;

        for (let i = 0; i < participants.length; i++) {
            if (participants[i].isApproved) {
                num++;
            }
        }

        return num;
    }

    const isMySpace = () => {
        return space.userId === twitterUser.id;
    }

    const handleCancel = async() => {
        const url = `${process.env.REACT_APP_API_URL}/space/${space.id}`;

        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        const resp = await axios.delete(url, { ...headers });

        setCancelOpen(false);
        refreshSpaces();
        console.log(url)
    }

    return (
        <>
        <Card sx={{ borderRadius: 2 }}>
            <CardHeader 
                title={space.name}
                subheader={space.description}
            />
            <CardContent>
                <Box>
                        <Stack direction="row" mb={2}>
                            <Avatar alt={space.user?.username} src={space.user?.profileImg} />

                            <Link sx={{ paddingTop: 1, paddingLeft: 2 }} href={'https://twitter.com/'+space.user?.username} target="_blank">@{space.user?.username}</Link>
                            <Typography pl={1} pt={1}>(host)</Typography>
                        </Stack>
                        
                        <Stack direction="row" mt={2} mb={2}>
                            <Avatar alt={space.user?.username}  />


                        {space.speakers && 
                        <Typography variant="p" pt={1} pl={2}> <Link href="#" onClick={() => setSpeakerOpen(true)}>{space.speakers} speakers</Link> ({space.speakers - approvedSpeakers(space.participants)} spots left) </Typography>}
                        </Stack>
                </Box>

                <Box mt={3} mb={3}>
                    {/* <Typography><em>{space.description}</em></Typography> */}
                    <Link target="_blank" href={space.link}>{space.link}</Link>
                </Box>
            
                <Typography variant="h6"><strong>🗓️  {formatDate(space.date)}</strong> (in your local hour)</Typography>
            </CardContent>

            <CardActions>
                {!isParticipating() && 
                    <Box pl={1} pr={4}>
                        <Tooltip title={twitterUser.id ? 'I will be there!' : 'Connect Twitter to participate!'}>
                            <Button variant="contained" onClick={() => handleParticipate(space)}>I WANT TO SPEAK!</Button>
                        </Tooltip>
                    </Box>}
                {(isApproved() || isPending()) && <Box pl={1} pr={4}><Button disabled={!twitterUser.id} variant="contained" onClick={() => handleUnParticipate(space)}>CANCEL PARTICIPATION</Button></Box>}
                {isPending() && <Typography color="secondary">🟠 Waiting for Host Approval!</Typography>}
                {/* <Typography>{approvedSpeakers(space.participants)} {approvedSpeakers(space.participants) === 1 ? 'speaker' : 'speakers'}</Typography> */}

                {(isMySpace() || isAdmin) && <Box><Button onClick={() => setCancelOpen(true)} variant="contained" color="error">Cancel</Button></Box>}
            </CardActions>
        </Card>

        <Modal
            open={speakerOpen}
            onClose={handleSpeakerClose}>
            <Box sx={modalStyle}>
                    <>
                    <Typography variant="h5">🎙️ Speakers ({approvedSpeakers(space.participants)} of {space.speakers})</Typography>
                    {approvedSpeakers(space.participants) === 0 && <Typography variant="h6" pt={2}>Be the first speaker!</Typography>}

                    {/* <Grid container spacing={2} pr={4}> */}
                    <List>

                        {space.participants.map(participant => {
                            return (
                                <>
                                {( isMySpace() || participant.user.username) && 
                                <ListItem
                                    sx={isMySpace() ? { minWidth: '400px' } : {}}
                                    secondaryAction={
                                        (isMySpace() && approvedSpeakers(space.participants) < space.speakers) && 
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button onClick={() => approveSpeaker(participant)} variant="contained" color="success" disabled={participant.isApproved}><CheckCircleIcon /></Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button onClick={() => denySpeaker(participant)}  variant="contained" color="error" disabled={!participant.isApproved && !participant.pending}><CancelIcon /></Button>
                                            </Grid>
                                        </Grid>
                                    }
                                >
                                    
                                    <ListItemAvatar>
                                        <Avatar alt={participant.user?.username} src={participant.user?.profileImg} />
                                    </ListItemAvatar>
                                    <ListItemText>@{participant.user?.username}</ListItemText>

                                    </ListItem>
                                }
                                </>
                            );
                        })}
                    </List>
                    </>
                
            </Box>
        </Modal>

        <Dialog
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"All cancellations are definitive!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will not be able to recover this space and its speakers.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setCancelOpen(false)}>Take me Back!</Button>
          <Button variant="contained" color="error"  onClick={handleCancel} autoFocus>
          Yes, Delete!
          </Button>
        </DialogActions>
      </Dialog>
    </>
    );
}

function Spaces({ twitterUser, isAdmin }) {
    const [upcoming, setUpcoming] = useState([]);
    const [myspaces, setMyspaces] = useState([]);
    const [participating, setParticipating] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [link, setLink] = useState('');
    const [speakers, setSpeakers] = useState('');
    const [tab, setTab] = useState('1');
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [tweetModalOpen, setTweetModalOpen] = useState(false);
    const [showConnect, setShowConnect] = useState(false);
    const [formHasErrors, setFormHasErrors] = useState(false);

    const handleConnectClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowConnect(false);
    };

    const handleTabChange = (_, newValue) => {
        if (newValue !== '1' && !twitterUser.id) {
            setShowConnect(true);
            return;
        }
        setTab(newValue);
    }
    
    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleDate = (value) => {        
        setDate(value);
    };

    const handleLink = (event) => {
        setLink(event.target.value);
    };

    const handleSpeakers = (event) => {
        setSpeakers(event.target.value);
    };

    const resetForm = () => {
        setName('');
        setDescription('');
        setDate('');
        setLink('');
        setSpeakers('');
    }

    const handleSave = async() => {
        // let timestamp = moment(date.toDate()).valueOf();
        // console.log(timestamp);
        // let formatted = moment.unix(timestamp / 1000);
        // console.log(moment(formatted).local().format('LLLL'));
        // return;

        if (name.length < 3 || description.length < 3 || date.length === 0 || !speakers) {
            setFormHasErrors(true);
            return;
        }

        const payload = {
            name,
            description,
            date: prepareDate(date),
            timezone: moment.tz.guess(),
            link,
            userId: twitterUser.id,
            speakers,
        };

        console.log(payload);
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/space`, payload, headers);
        await getUpcoming();
        await getMySpaces();
        await getParticipating();
        setAddModalOpen(false);
        setTweetModalOpen(true);
        resetForm();
    };

    const handleParticipate = async(space) => {
        if (!twitterUser.id) {
            return;
        }

        const payload = {
            userId: twitterUser.id,
            spaceId: space.id,
            date: new Date(),
        }

        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/space/participate`, payload, headers);
        console.log(resp);

        await getUpcoming();
        await getMySpaces();
        await getParticipating();
    }

    const handleUnParticipate = async(space) => {
        const payload = {
            userId: twitterUser.id,
            spaceId: space.id,
        }
        console.log(payload);
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        const resp = await axios.delete(`${process.env.REACT_APP_API_URL}/space/participate`, { ...headers, data: payload });
        // console.log(resp);

        await getUpcoming();
        await getMySpaces();
        await getParticipating();
    }


    const getUpcoming = async() => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/space/upcoming`);

        setUpcoming(data);
    }
    
    const getMySpaces = async() => {
        if (twitterUser.id) {
            let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/space/my/${twitterUser.id}`, headers);

            setMyspaces(data);
        }
    }

    const getParticipating = async() => {
        if (twitterUser.id) {
            let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/space/participating/${twitterUser.id}`, headers);

            setParticipating(data);
        }
    }


    const approveSpeaker = async(participant) => {
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};
        
        const url = `${process.env.REACT_APP_API_URL}/space/participate/approve/${participant.id}`;
        const resp = await axios.post(url, {}, headers);

        refreshSpaces();
        console.log(`approving`, participant);

    }

    const denySpeaker = async(participant) => {
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        const url = `${process.env.REACT_APP_API_URL}/space/participate/deny/${participant.id}`;
        const resp = await axios.post(url, {}, headers);
        refreshSpaces();
        console.log(`denying`, participant);
    }

    const refreshSpaces = () => {
        getUpcoming();
        getMySpaces();
        getParticipating();
    }

    useEffect(() => {
        getUpcoming();
        getMySpaces();
        getParticipating();
    }, []);

    useEffect(() => {
        if (tab === '1') {
            getUpcoming();
        }

        if (tab === '2') {
            getMySpaces();
        }

        if (tab === '3') {
            getParticipating();
        }
    }, [tab]);

    return (
        <Container>
            <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display={{ xs: 'none', sm: 'none', md: 'block' }}>
            <TabList onChange={handleTabChange} >
                <Tab icon={<EventNoteIcon />} iconPosition="start" label="Upcoming Spaces" value="1" />
                <Tab icon={<StarIcon />} iconPosition="start" label="Create My Space" value="2" />
                <Tab icon={<MicIcon />} iconPosition="start" label="I Want To Speak!" value="3" />
            </TabList>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display={{ xs: 'block', sm: 'block', md: 'none' }}>
            <TabList onChange={handleTabChange} >
                <Tab icon={<EventNoteIcon />} iconPosition="start" value="1" />
                <Tab icon={<StarIcon />} iconPosition="start" value="2" />
                <Tab icon={<MicIcon />} iconPosition="start" value="3" />
            </TabList>
            </Box>
        <TabPanel value="1">
            {upcoming.length < 1 &&
                <Typography variant="h6">No upcoming spaces found!</Typography>
            }
            {/* <Grid container spacing={2}> */}
            <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2}>
                {upcoming.length > 0 && upcoming.map(space => 
                    <Space space={space} twitterUser={twitterUser} isAdmin={isAdmin} handleParticipate={handleParticipate} handleUnParticipate={handleUnParticipate} approveSpeaker={approveSpeaker} denySpeaker={denySpeaker} refreshSpaces={refreshSpaces} />
                )}
                </Masonry>
            {/* </Grid> */}
        </TabPanel>
        <TabPanel value="2">
            {twitterUser.id && 
            <>
            {myspaces.length < 1 && 
                <Typography variant="h6" mb={3}>Hosting a space? Add it easily below!</Typography>
            }
            <Box mb={3}>
                <Button variant="contained" onClick={() => setAddModalOpen(true)}>Add New Space</Button>
            </Box>

            <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2}>
                    {myspaces.length > 0 && myspaces.map(space => 
                        <Space space={space} twitterUser={twitterUser} isAdmin={isAdmin} approveSpeaker={approveSpeaker} denySpeaker={denySpeaker}  handleParticipate={handleParticipate} handleUnParticipate={handleUnParticipate} refreshSpaces={refreshSpaces} />
                    )}
            </Masonry>

            </>
            }

            {!twitterUser.id && 
            <>
                <Typography variant="h6">Connect your Twitter to add your space!</Typography>
            </>
            }
        </TabPanel>
        <TabPanel value="3">
             <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2}>
                    {!participating.length &&
                        <Box>
                            <Typography variant="h6">Check out <Button sx={{ marginX: 1 }} variant="contained" onClick={() => setTab('1')}>Upcoming</Button> for spaces to participate in!</Typography>
                        </Box>
                    }
                    {participating.length > 0 && participating.map(space => 
                            <Space space={space} twitterUser={twitterUser} isAdmin={isAdmin} handleParticipate={handleParticipate} handleUnParticipate={handleUnParticipate} approveSpeaker={approveSpeaker} denySpeaker={denySpeaker} refreshSpaces={refreshSpaces} />
                    )}
            </Masonry>
        </TabPanel>
      </TabContext>

        <Modal open={addModalOpen}
          onClose={() => setAddModalOpen(false)}>
                    <Box sx={modalStyle}>

                    <Typography variant="h6">Let's setup details on the Space you're hosting 👇</Typography>

                    <Grid container spacing={2} mt={1}>
                        <Grid item>
                        
                        <FormControl fullWidth margin="normal">
                            <TextField required error={formHasErrors && name.length < 3} size="small" value={name} onChange={handleName} label="Name" variant="outlined" />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <TextField required  error={formHasErrors && description.length < 3} multiline size="small" rows="4" value={description} onChange={handleDescription} label="Description" variant="outlined" />
                        </FormControl>
                        
                        <FormControl fullWidth margin="normal" required>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker label="Date (in your local time) *" value={date} onChange={handleDate} />
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <TextField size="small" value={link} onChange={handleLink} label="Twitter Link" variant="outlined" />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <TextField  error={formHasErrors && !speakers} required size="small" type="number" max="12" value={speakers} onChange={handleSpeakers} label="Number of Speakers" variant="outlined" />
                        </FormControl>

                        <FormControl margin="normal">
                            <Button variant="contained" onClick={handleSave}>Let's Go!</Button>
                        </FormControl>  

                        </Grid>
                    </Grid>
            </Box>
        </Modal>

        <Modal
            open={tweetModalOpen}
            onClose={() => setTweetModalOpen(false)}
            >
                <Box sx={modalStyle}>
                    <Typography variant="h6">All Good! 🎉</Typography>
                    <Typography mt={1} mb={2}>Don't keep your space secret... share it on Twitter!</Typography>
                    <Button variant="contained" className="twitter-share-button"
                        href="https://twitter.com/intent/tweet?text=%F0%9F%8E%A4 Join me on my Twitter Space at https://dudestoolbox.xyz/circle and book your speaker spot NOW! %0A%0ALet's connect and inspire together. %F0%9F%AB%B6"
                        data-size="large"
                        data-url="https://dudestoolbox.xyz/circle"
                        endIcon={<TwitterIcon />}
                        >
                        Tweet!
                    </Button>     
                </Box>
            </Modal>
        <Snackbar open={showConnect} autoHideDuration={6000} onClose={handleConnectClose}>
        <Alert onClose={handleConnectClose} severity="info" sx={{ width: '100%' }}>
          Connect your Twitter to access!
        </Alert>
      </Snackbar>

            

        </Container>
    )
}

export default Spaces;