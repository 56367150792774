import { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

function TypeMenu({ switchType, counter, typeId }) {
    const [types, setTypes] = useState([]);

    const getTypes = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/type`);

        setTypes(resp.data);
    }
  
    const handleChange = (event, newType) => {
        if (newType !== null) {
            switchType(newType);
        }
    }

    useEffect(() => {
        getTypes();
    }, []);

    useEffect(() => {
        getTypes();
    }, [counter]);

    return (
        <ToggleButtonGroup color="primary" onChange={handleChange} value={typeId} exclusive>
            {types.length && types.map(type => 
                <ToggleButton key={type.id} value={type.id} sx={{ textTransform: 'none' }}>
                     {type.name}
                </ToggleButton>)
            }
        </ToggleButtonGroup>
    );
}

export default TypeMenu;