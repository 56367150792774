import { useState } from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Cookies from 'js-cookie';

function CategoryForm({ id, handleClose }) {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleColor = (event) => {
        setColor(event.target.value);
    };

    const handleSave = async() => {
        let payload = {
            name,
            color,
        };

        if (id) {
            // update
        } else {
            // create
           let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/category`, payload, headers);
            handleClose();

        }

    }

    return (
        <>
            <h2>Category Form</h2>
            <Grid container spacing={2}>
                <Grid item>
                
                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={name} onChange={handleName} label="Name" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={color} onChange={handleColor} label="Color" variant="outlined" />
                </FormControl>

                <FormControl margin="normal">
                    <Button variant="contained" onClick={handleSave}>Save Changes</Button>
                </FormControl> 

                </Grid>
           </Grid>
        </>
    )
}

export default CategoryForm;