import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TwitterIcon from '@mui/icons-material/Twitter';
import Marquee from "react-fast-marquee";
import LightMode from '@mui/icons-material/LightMode';
import { WalletMultiButton } from  "@solana/wallet-adapter-react-ui";

const pages = [
  // { name: 'Portfolio', url: 'https://dudes.lfgrow.xyz/login' },
  // { name: 'Stake', url: 'https://stake.cardinal.so/supportivedudes' },
  { name: 'Raffles', url: 'https://monet.community/p/supportivedudes' },
  // { name: 'Auctions', url: 'https://soleddarity-utilities.vercel.app/auctions' },
  // { name: 'Raffles', url: 'https://soleddarity-utilities.vercel.app/raffles' },
  { name: 'Buy a Dude!', url: 'https://magiceden.io/creators/dudes' },
  { name: 'Upgrade', url: 'https://dudes-upgrade.vercel.app/' },
];

function NavBar({ loginStatus, signedInUser, toggleColorMode }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const [solPrice, setSolPrice] = useState(0);
  const [ethPrice, setEthPrice] = useState(0);
  const [maticPrice, setMaticPrice] = useState(0);
  const [aptosPrice, setAptosPrice] = useState(0);
  const [btcPrice, setBtcPrice] = useState(0);
  const [pricesLoaded, setPricesLoaded] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileOpen = () => {
    handleCloseUserMenu();
  };

  const handleTwitterLogin = () => {
    const loginUrl = `${process.env.REACT_APP_API_URL}/auth/twitter?redirect=${location.pathname}`;
    window.location = loginUrl;
  }

  const handleTwitterLogout = () => {
    const logoutUrl = `${process.env.REACT_APP_API_URL}/auth/logout?redirect=${location.pathname}`;
    window.open(logoutUrl, '_self');
}

  const fetchPrices = async() => {
    const url = `https://api.coingecko.com/api/v3/simple/price?ids=solana%2Cethereum%2Cmatic-network%2Captos%2Cbitcoin&vs_currencies=usd`;

    const { data } = await axios.get(url);

    setSolPrice(data.solana.usd.toLocaleString());
    setEthPrice(data.ethereum.usd.toLocaleString());
    setMaticPrice(data['matic-network'].usd);
    setAptosPrice(data.aptos.usd);
    setBtcPrice(data.bitcoin.usd.toLocaleString());

    setPricesLoaded(true);
  }

  useEffect(() => {
    fetchPrices();
  }, []);

useEffect(() => {
    async function getStatus() {
        const resp = await fetch(`${process.env.REACT_APP_API_URL}/auth/login/success`, {
            method: "GET",
            credentials: "include",
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true
            }
            });
        if (resp.status !== 200) {
            setAuthenticated(false);
            setUser({});
            signedInUser({});
            loginStatus(false);
            return;
        }

        const jsonResp = await resp.json();
        console.log(jsonResp);
        setUser(jsonResp.user);
        signedInUser(jsonResp.user);
        setAuthenticated(true);
        loginStatus(true);

    }

    getStatus();
}, [user.id]);

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box mt={1} mr={5}>
            <NavLink to="/">
            <img width="40" src="https://www.dudestoolbox.xyz/assets/images/logo.png" />
            </NavLink>
        </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
            <MenuItem key="wallet">
              <WalletMultiButton className="wallet-button"  />
            </MenuItem>

            <MenuItem key="spaces" component="a" href="/circle">
                <Typography textAlign="center">Circle</Typography>
              </MenuItem>
              {pages.map((page) => (
                <MenuItem key={page.name} component="a" href={page.url} target="_blank">
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
              
            </Menu>
            
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button key="spaces" href="/circle" sx={{ my: 2, color: 'white', display: 'block', mr: 1 }}>
              Circle
            </Button>

            {pages.map((page) => (
              <Button
                key={page.name}
                // onClick={handleCloseNavMenu}
                href={page.url}
                target="_blank"
                sx={{ my: 2, color: 'white', display: 'block', mr: 1 }}
              >
                {page.name}
              </Button>
            ))}



          </Box>
          <Box mr={4}>
          <IconButton onClick={toggleColorMode}><LightMode /></IconButton>

          </Box>

          {pricesLoaded && <Box sx={{ display: { xs: 'none', md: 'flex' }, width: 220 }} mr={2}>
            <Marquee gradientColor={0} pauseOnHover="true">
              <img src="/assets/images/solana.svg" width="24" /><Typography pl={1} pr={4}>SOL/USD: <strong>${solPrice}</strong></Typography>
              <img src="/assets/images/ethereum.svg" width="24" /><Typography pl={1} pr={4}>ETH/USD: <strong>${ethPrice}</strong></Typography>
              <img src="/assets/images/polygon.svg" width="24" /><Typography pl={1} pr={4}>MATIC/USD: <strong>${maticPrice}</strong></Typography>
              <img src="/assets/images/aptos.svg" width="24" /><Typography pl={1} pr={4}>APT/USD: <strong>${aptosPrice}</strong></Typography>
              <img src="/assets/images/bitcoin.png" width="24" /><Typography pl={1} pr={4}>BTC/USD: <strong>${btcPrice}</strong></Typography>
            </Marquee>
          </Box>}

          <Box display="inline" mr={{ xs: 1, md: 2 }} ml={{ xs: 0, md: 2}}>
           <Typography display={{ xs: 'none', md: 'flex' }}>Points? 💎 </Typography>
           <Typography display={{ xs: 'flex', md: 'none' }} fontSize={10}>💎 ?</Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Box mr={2} display={{ xs: 'none', sm: 'none', md: 'inline' }}>
              <WalletMultiButton className="wallet-button"  />
            </Box>

          {!authenticated && 
            <Tooltip title="Unlock more features!">
                <Button variant="contained" onClick={handleTwitterLogin} startIcon={<TwitterIcon />}>Connect</Button>
            </Tooltip>
          }
          {authenticated && <>
            {/* <Tooltip title="Open settings">
              
             </Tooltip> */}
             {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton> */}
              <Button variant="contained" onClick={handleOpenUserMenu} startIcon={<TwitterIcon />}>{user.username}</Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
            <MenuItem key="profile" onClick={handleProfileOpen} disabled>
                My Profile (soon)
            </MenuItem>
            <MenuItem key="disconnect" onClick={handleTwitterLogout}>
                Disconnect
            </MenuItem>
            </Menu>
            </>}
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;