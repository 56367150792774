import { useState } from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Cookies from 'js-cookie';

function TypeForm({ id, handleClose }) {
    const [name, setName] = useState('');

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleSave = async() => {
        let payload = {
            name,
        };

        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        if (id) {
            // update
        } else {
            // create
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/type`, payload, headers);
            handleClose();
        }
    }

    return (
        <>
            <h2>Type Form</h2>
            <Grid container spacing={2}>
                <Grid item>
                
                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={name} onChange={handleName} label="Name" variant="outlined" />
                </FormControl>

                <FormControl margin="normal">
                    <Button variant="contained" onClick={handleSave}>Save Changes</Button>
                </FormControl> 

                </Grid>
           </Grid>
        </>
    )
}

export default TypeForm;