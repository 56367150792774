import { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function TypeSelect({ switchType, counter, typeId }) {
    const [types, setTypes] = useState([]);

    const getTypes = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/type`);

        setTypes(resp.data);
    }
  
    const handleChange = (event) => {
        switchType(event.target.value);
    }

    useEffect(() => {
        getTypes();
    }, []);

    useEffect(() => {
        getTypes();
    }, [counter]);

    return (
        <Select color="primary" onChange={handleChange} value={typeId} fullWidth>
            {types.length > 0 && types.map(type => 
                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
            )}
        </Select>
    );
}

export default TypeSelect;