import { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import DiscordIcon from '../assets/icons/Discord';
import TwitterIcon from '@mui/icons-material/Twitter';
import WebsiteIcon from '@mui/icons-material/Language';
import FavoriteIcon from '@mui/icons-material/StarOutline';
import UnfavoriteIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';

const buttonStyle = {
    marginRight: 1,
};

function Tools({ chainId, typeId, handleEditTool, twitterUser, isAdmin }) {
    const [tools, setTools] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [favoritesOnly, setFavoritesOnly] = useState(false);
    const [filterText, setFilterText] = useState('');

    const getTools = async(chainId) => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/tool/chain/${chainId}`);
        setTools(resp.data);

        await getFavorites();
    }

    const getFavorites = async() => {
        const favorites = await axios.get(`${process.env.REACT_APP_API_URL}/favorite/${twitterUser.id}`);
        setFavorites(favorites.data);
    }

    const isFavorite = (toolId) => {
        for (let i = 0; i < favorites.length; i++) {
            if (favorites[i].toolId === toolId) {
                return true;
            }
        }

        return false;
    }

    const handleAddFavorite = async(toolId) => {
        const payload = {
            toolId,
            userId: twitterUser.id,
        };

        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/favorite`, payload);

        await getFavorites();
    };

    const handleRemoveFavorite = async(toolId) => {
        const resp = await axios.delete(`${process.env.REACT_APP_API_URL}/favorite/${twitterUser.id}/${toolId}`);

        await getFavorites();
    };

    const handleFavoritesOnly = (event) => {
        setFavoritesOnly(event.target.checked);
    }

    const handleFilterTextChange = (event) => {
        setFilterText(event.target.value);
    }

    const showTool = (tool) => {
        if (tool.toolTypeId !== typeId) {
            return false;
        }

        if (favoritesOnly && !isFavorite(tool.id)) {
            return false;
        }

        if (filterText.length > 1 && !tool.name.toLowerCase().includes(filterText.toLowerCase())
            && !tool.description.toLowerCase().includes(filterText.toLowerCase())
            && !inCategories(filterText, tool.categories)
        ) {
            return false;
        }

        return true;
    }

    const inCategories = (text, categories) => {
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        getFavorites();
    }, [twitterUser]);

    useEffect(() => {
        getTools(chainId);
    }, [chainId]);

    return (
        <div>
            {twitterUser.id && <>
                <Grid container justifyContent="flex-end" pr={4} mt={2}>
                    <Grid item>
                        <FormControlLabel control={<Switch checked={favoritesOnly} onChange={handleFavoritesOnly} />} label="Favorites Only" />
                    </Grid>
                    
                    <Grid item ml={4}>
                        <TextField sx={{ maxWidth: 150 }} size="small" placeholder="Tool Filter..." value={filterText} onChange={handleFilterTextChange} />
                    </Grid>
                </Grid>
            </>
            }
            <Grid container spacing={2} pr={4} mt={1}>
            {tools.length > 0 && tools.map(tool => 
                <>
                {showTool(tool) && 
                    <Grid item xs={12} md={12} lg={6} xl={4}>

                    <Card variant="outlined" sx={{ minHeight: 300, border: 0, background: `linear-gradient(${tool.colorFrom} 20%, ${tool.colorTo} 100%)`}}>
                    <CardContent>
                        <Box display="flex">
                            <Box flexGrow={1} className="tool-icon-container">
                                <img src={tool.icon} />
                            </Box>

                            <Box p={1}>
                                {tool.categories.map(cat => 
                                    <Chip label={cat.name} sx={{ bgcolor: cat.color, marginRight: 1 }} /> 
                                )}
                            </Box>
                        </Box>

                        <Typography mt={3} gutterBottom variant="h5" component="div" color="white">
                            {tool.name}
                        </Typography>

                        <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                            {tool.description}
                        </Typography>                      
                        
                    </CardContent>
                    
                    <CardActions sx={{ marginLeft: 1 }}>
                        <Grid container>
                            <Grid item xs={10} display={{ xs: 'block', sm: 'block', md: 'none' }}>
                                <Button size="small" variant="contained" sx={buttonStyle} href={tool.twitter} target="_blank"><TwitterIcon /></Button>
                                <Button size="small" variant="contained" sx={buttonStyle} href={tool.discord} target="_blank"><DiscordIcon /></Button>
                                <Button size="small" variant="contained" sx={buttonStyle} href={tool.website} target="_blank"><WebsiteIcon /></Button>
                            </Grid>
                          
                            <Grid item xs={9} xl={10} display={{ xs: 'none', sm: 'none', md: 'block' }}>
                                <Button size="small" variant="contained" sx={buttonStyle} startIcon={<TwitterIcon />} href={tool.twitter} target="_blank">Twitter</Button>
                                <Button size="small" variant="contained" sx={buttonStyle} startIcon={<DiscordIcon />} href={tool.discord} target="_blank">Discord</Button>
                                <Button size="small" variant="contained" sx={buttonStyle} startIcon={<WebsiteIcon />} href={tool.website} target="_blank">Access</Button>
                            </Grid>

                        <Grid item xs={2} xl={2} justifyContent="flex-end" textAlign="right">
                            {isAdmin && <Button onClick={() => handleEditTool(tool.id)} size="small"><EditIcon /></Button>}
                            {twitterUser.id && <>
                            {isFavorite(tool.id) ?                         
                                <Button onClick={() => handleRemoveFavorite(tool.id)} size="small"><UnfavoriteIcon /></Button>
                                :
                                <Button onClick={() => handleAddFavorite(tool.id)} size="small"><FavoriteIcon /></Button>
                            }
                            </>
                            }
                        </Grid>
                        </Grid>
                        
                     </CardActions>
                    </Card>  
                    </Grid>
                    
                }
                </>
            )}
            </Grid>
            
        </div>
    )
}

export default Tools;