import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

function ChainMenu({ switchChain, counter, chainId, orientation, chainName }) {
    const [chains, setChains] = useState([]);
    const [chain, setChain] = useState(null);

    const getChains = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/chain`);

        setChains(resp.data);

        if (!chain) {
            switchChain(resp.data[0].id);
            setChain(resp.data[0]);
        }
    }
    
    const handleChange = (_, chainId) => {
        switchChain(chainId);
        
        for (let i = 0; i < chains.length; i++) {
            if (chains[i].id === chainId) {
                setChain(chains[i]);
            }
        }
    }

    const handleButtonChange = (event, chainId) => {
        switchChain(chainId);
        
        for (let i = 0; i < chains.length; i++) {
            if (chains[i].id === chainId) {
                setChain(chains[i]);
            }
        }
    }

    useEffect(() => {
        getChains();
    }, []);

    useEffect(() => {
        getChains();
    }, [counter]);


    return (
        <div>
        {orientation !== 'horizontal' && <Box
            sx={{ flexGrow: 1, display: 'flex' }}
        >
            <Tabs value={chainId} onChange={handleChange} orientation={orientation}> 
                {chains.length && chains.map(ch => 
                    <Tab key={ch.name} value={ch.id} label={
                    <Box >
                        <img width="24" src={ch.logo} />
                        {orientation !== 'horizontal' && <Typography>{ch.name}</Typography>}
                    </Box>} />
                )}
            </Tabs>
        </Box>}

        {orientation === 'horizontal' && <Box
            sx={{ flexGrow: 1, display: 'flex' }}
        >
        <ToggleButtonGroup color="primary" onChange={handleButtonChange} value={chainId} exclusive>
            {chains.length && chains.map(ch => 
                <ToggleButton key={ch.id} value={ch.id}>
                     <img src={ch.logo} width="24" />
                </ToggleButton>
            )}
        </ToggleButtonGroup>
        </Box>}
          
        </div>
    );
}

export default ChainMenu;