import { useState, useEffect, createContext, useMemo } from 'react';

import '../App.css';
import ChainMenu from './ChainMenu';
import TypeMenu from './TypeMenu';
import TypeSelect from './TypeSelect';
import Tools from './Tools';
import ToolForm from './admin/ToolForm';
import ChainForm from './admin/ChainForm';
import TypeForm from './admin/TypeForm';
import CategoryForm from './admin/CategoryForm';
import Container from "@mui/material/Container";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AdminMenu from './admin/AdminMenu';
import Drawer from '@mui/material/Drawer';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: '#333',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };

function Home({ authenticated, setAuthenticated, twitterUser, setTwitterUser, isAdmin, setIsAdmin}) {
    const [chainId, setChainId] = useState(1);
    const [chainName, setChainName] = useState(null);

    const [chainCounter, setChainCounter] = useState(0);
    const [typeId, setTypeId] = useState(1);
    const [typeCounter, setTypeCounter] = useState(0);
    const [toolModalOpen, setToolModalOpen] = useState(false);
    const [chainModalOpen, setChainModalOpen] = useState(false);
    const [typeModalOpen, setTypeModalOpen] = useState(false);
    const [categoryModalOpen, setCategoryModalOpen] = useState(false);
    const [toolId, setToolId] = useState(null);
  
    useEffect(() => {
        if (authenticated) {
          const jwt = Cookies.get('token');
          
          if (jwt) {
              const decoded = jwt_decode(jwt);
              console.log(decoded);
              if (decoded.permissions && decoded.permissions.includes('is_admin')) {
                  setIsAdmin(true);
                  return;
              }
          }
        }
      }, [authenticated]);
    
      const openModal = (fn) => {
        if (fn === 'setChainModalOpen') {
          setChainModalOpen(true);
        } else if (fn === 'setToolModalOpen') {
          setToolModalOpen(true);
        } else if (fn === 'setCategoryModalOpen') {
          setCategoryModalOpen(true);
        } else if (fn === 'setTypeModalOpen') {
          setTypeModalOpen(true);
        }
      }
    
      const switchChain = (chainId) => {
        console.log(`switch chain`, chainId)
        setChainId(chainId);
      }
    
      const switchType = (typeId) => {
        setTypeId(typeId);
      }
    
      const handleEditTool = (toolId) => {
        setToolId(toolId);
        setToolModalOpen(true);
      }
    
      const handleToolFormClose = () => {
        let currentChainId = chainId;
        setChainId(null);
    
        setToolModalOpen(false);
    
        setTimeout(() => {
          setChainId(currentChainId);
        }, 100);
      }
    
      const handleChainFormClose = (newChainId) => {
        let next = chainCounter + 1;
        setChainCounter(next);
    
        setChainModalOpen(false);        
      };
    
      const handleTypeFormClose = () => {
        let next = typeCounter + 1;
        setTypeCounter(next);
    
        setTypeModalOpen(false);
      };
    
      const handleCategoryFormClose = () => {
        let currentChainId = chainId;
        setChainId(null);
    
        setCategoryModalOpen(false);
    
        setTimeout(() => {
          setChainId(currentChainId);
        }, 100);
      };

    return (
        <>
        <Grid container mt={4} mb={4}>
        

        <Grid item sm={12} display={{ xs: 'block', sm: 'block', md: 'none' }} ml={4}>
            <ChainMenu chainId={chainId} switchChain={switchChain} chainName={chainName} counter={chainCounter} orientation="horizontal" />
        </Grid>

        <Grid item md={2} lg={1} display={{ xs: 'none', sm: 'none', md: 'block' }}>
          <ChainMenu chainId={chainId} switchChain={switchChain} chainName={chainName} counter={chainCounter} orientation="vertical" />
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={11} sx={{maxHeight: '100vh', overflow: 'auto'}}>
          <Box ml={4} display={{ xs: 'block', sm: 'block', md: 'none' }}>
            <Box mr={4} mt={2} mb={2}>
              <TypeSelect typeId={typeId} switchType={switchType} counter={typeCounter} />
            </Box>
            <Box>
              <Tools chainId={chainId} typeId={typeId} handleEditTool={handleEditTool} twitterUser={twitterUser} />
              </Box>
          </Box>
          
          <Box ml={4} display={{ xs: 'none', sm: 'none', md: 'block' }}>
            <TypeMenu typeId={typeId} switchType={switchType} counter={typeCounter} />
            <Box>
              <Tools isAdmin={isAdmin} chainId={chainId} typeId={typeId} handleEditTool={handleEditTool} twitterUser={twitterUser} />
              </Box>
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={toolModalOpen}
        onClose={() => setToolModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <ToolForm id={toolId} handleClose={handleToolFormClose} />      
        </Box>
      </Modal>

      <Modal
        open={chainModalOpen}
        onClose={() => setChainModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <ChainForm handleClose={handleChainFormClose} />
        </Box>
      </Modal>
      
      <Modal
        open={typeModalOpen}
        onClose={() => setTypeModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <TypeForm handleClose={handleTypeFormClose} />
        </Box>
      </Modal>

      <Modal
        open={categoryModalOpen}
        onClose={() => setCategoryModalOpen(false)}
      >
        <Box sx={modalStyle}>
          <CategoryForm handleClose={handleCategoryFormClose} />
        </Box>
      </Modal>

      {isAdmin && <AdminMenu openModal={openModal} />}
      </>
    )
}

export default Home;