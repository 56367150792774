import { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import axios from 'axios';
import Cookies from 'js-cookie';

// category should be checkbox list

function ToolForm({ id, handleClose }) {
    const [chains, setChains] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [toolId, setToolId] = useState(id ?? '');
    const [chain, setChain] = useState('');
    const [type, setType] = useState('');
    const [category, setCategory] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState('');
    const [twitter, setTwitter] = useState('');
    const [discord, setDiscord] = useState('');
    const [website, setWebsite] = useState('');
    const [colorFrom, setColorFrom] = useState('');
    const [colorTo, setColorTo] = useState('');

    const handleChain = (event) => {
        setChain(event.target.value);
    };

    const handleType = (event) => {
        setType(event.target.value);
    };

    const handleCategory = (event, newValue) => {
        setCategory(newValue);
    };

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleIcon = (event) => {
        setIcon(event.target.value);
    };

    const handleTwitter = (event) => {
        setTwitter(event.target.value);
    };

    const handleDiscord = (event) => {
        setDiscord(event.target.value);
    };

    const handleWebsite = (event) => {
        setWebsite(event.target.value);
    };

    const handleColorFrom = (event) => {
        setColorFrom(event.target.value);
    };

    const handleColorTo = (event) => {
        setColorTo(event.target.value);
    };


    const handleDelete = async() => {
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};

        if (id) {
            await axios.delete(`${process.env.REACT_APP_API_URL}/tool/${id}`, headers);
        }

        handleClose();
    }

    const handleSave = async() => {
        let payload = {
            chainId: chain,
            toolTypeId: type,
            name,
            description,
            icon,
            twitter,
            discord,
            website,
            colorFrom,
            colorTo,
            // categories
        }
       
        let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};


        if (id) {
            await axios.put(`${process.env.REACT_APP_API_URL}/tool/${id}`, payload, headers);
        } else {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/tool`, payload, headers);
            id = resp.data.id;
        }

        // handle categories
        await axios.delete(`${process.env.REACT_APP_API_URL}/category/${id}`, headers);
        for (let i = 0; i < category.length; i++) {
            let payload = {
                toolId: id,
                name: category[i].name,
                color: category[i].color,
                isHidden: category[i].isHidden,
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/category`, payload, headers);
        }


        handleClose();
    }
    
    const getChains = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/chain`);

        setChains(resp.data);
    }
    
    const getTypes = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/type`);

        setTypes(resp.data);
    }

    const getCategories = async() => {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/category`);

        setCategories(resp.data);
    }

    const getTool = async(toolId) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tool/${toolId}`);

        if (data) {
            setChain(data.chainId);
            setType(data.toolTypeId);
            setName(data.name);
            setDescription(data.description);
            setIcon(data.icon);
            setDiscord(data.discord);
            setTwitter(data.twitter);
            setWebsite(data.website);
            setColorFrom(data.colorFrom);
            setColorTo(data.colorTo);
            setCategory(data.categories);
        }
    }

    useEffect(() => {
        getChains();
        getTypes();
        getCategories();
    }, []);

    useEffect(() => {
        if (chains.length > 0) {
            if (id) {
                getTool(id);
            }
        }
     }, [chains]);

    return (
        <>
            <h2>Tool Form</h2>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                    <InputLabel size="small" id="chainSelectLabel">Chain</InputLabel>
                    <Select
                        labelId="chainSelectLabel"
                        value={chain}
                        label="Chain"
                        onChange={handleChain}
                        size="small"
                    >
                    {chains.length && chains.map((ch) =>
                        <MenuItem key={ch.id} value={ch.id}>{ch.name}</MenuItem>
                    )};
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel size="small" id="typeSelectLabel">Type</InputLabel>
                    <Select
                        labelId="typeSelectLabel"
                        value={type}
                        label="Type"
                        onChange={handleType}
                        size="small"
                    >
                    {types.length && types.map((t) =>
                        <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                    )};
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={name} onChange={handleName} label="Name" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" multiline rows="4" value={description} onChange={handleDescription} label="Description" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    {/* <InputLabel size="small" id="categorySelectLabel">Category</InputLabel> */}

                    <Autocomplete
                        multiple
                        options={categories}
                        value={category}
                        getOptionLabel={(option) => option.name}
                        onChange={handleCategory}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Categories"
                        />
                        )}
                    />
                </FormControl>

                <Box display="flex" justifyContent="space-between">
                    <FormControl margin="normal">
                        <Button variant="contained" onClick={handleSave}>Save Changes</Button>
                    </FormControl>

                    <FormControl margin="normal">
                        <Button variant="contained" onClick={handleDelete}>Delete Tool</Button>
                    </FormControl>
                </Box>        

                </Grid>

                <Grid item xs={6}>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={icon} onChange={handleIcon} label="Icon" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={discord} onChange={handleDiscord} label="Discord" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={twitter} onChange={handleTwitter} label="Twitter" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={website} onChange={handleWebsite} label="Website" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={colorFrom} onChange={handleColorFrom} label="Color From" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={colorTo} onChange={handleColorTo} label="Color To" variant="outlined" />
                </FormControl>
                </Grid>
           </Grid>
        </>
    )
}

export default ToolForm;