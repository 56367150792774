import { useState, useEffect, createContext, useMemo } from 'react';
import { Link, Route, Routes, NavLink } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import NavBar from './components/NavBar';
import Grid from '@mui/material/Grid';
import AdminMenu from './components/admin/AdminMenu';
import Drawer from '@mui/material/Drawer';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";
import Home from './components/Home';
import Spaces from './components/Spaces';
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  // GlowWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  // BackpackWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import '@solana/wallet-adapter-react-ui/styles.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#14171f',
    }
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: '#333',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [twitterUser, setTwitterUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  
  useEffect(() => {
    if (authenticated) {
      const jwt = Cookies.get('token');
      
      if (jwt) {
          const decoded = jwt_decode(jwt);
          console.log(decoded);
          if (decoded.permissions && decoded.permissions.includes('is_admin')) {
              setIsAdmin(true);
              return;
          }
      }
    }
  }, [authenticated]);

  const loginStatus = (authStatus) => {
    setAuthenticated(authStatus);
  }

  const signedInUser = (user) => {
    setTwitterUser(user);
  }

  const [mode, setMode] = useState('dark');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light'
      ? {
          // palette values for light mode
         primary: {
           main: '#00727D',
         }
        }
      : {
          // palette values for dark mode
          background: {
            default: '#14171f',
          },
        }),
        },
      }),
    [mode],
  );

  const endpoint = "http://localhost:8899";

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      // new GlowWalletAdapter(),
      // new BackpackWalletAdapter(),
    ],
    []
  );


  return (
    <div className="App">
          <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
          <WalletModalProvider>

        <ColorModeContext.Provider value={colorMode}>

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar signedInUser={signedInUser} loginStatus={loginStatus} toggleColorMode={colorMode.toggleColorMode} />
      
      <Routes>
          <Route path="/" element={<Home 
            authenticated={authenticated} 
            setAuthenticated={setAuthenticated}
            twitterUser={twitterUser}
            setTwitterUser={setTwitterUser}
            isAdmin={isAdmin}
            setIsAdmin={setIsAdmin} />}/>
          <Route exact path="/circle" element={<Spaces twitterUser={twitterUser} isAdmin={isAdmin} />}/>
      </Routes>

      </ThemeProvider>
      </ColorModeContext.Provider>
      </WalletModalProvider>

      </WalletProvider>
            </ConnectionProvider>
    </div>
  );
}

export default App;
