import { useState } from 'react';
import axios from 'axios';
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Cookies from 'js-cookie';

function ChainForm({ id, handleClose }) {
    const [name, setName] = useState('');
    const [logo, setLogo] = useState('');
    const [isHidden, setIsHidden] = useState(false);

    const handleName = (event) => {
        setName(event.target.value);
    };

    const handleLogo = (event) => {
        setLogo(event.target.value);
    };

    const handleHidden = (event) => {
        setIsHidden(event.target.checked);
    };

    const handleSave = async() => {
        let payload = {
            name,
            logo,
            isHidden
        };

        if (id) {
            // update
        } else {
            // create
            let headers = { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${Cookies.get('token')}` }};
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/chain`, payload, headers); 
            handleClose(resp.data.id);
        }

    }

    return (
        <>
            <h2>Chain Form</h2>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                
                <FormControl fullWidth margin="normal">
                    <TextField size="small" value={name} onChange={handleName} label="Name" variant="outlined" />
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <TextField size="small" rows="4" value={logo} onChange={handleLogo} label="Logo image URL" variant="outlined" />
                </FormControl>
                
                <FormControl fullWidth margin="normal">
                    <FormControlLabel control={<Checkbox onChange={handleHidden} />} label="Hidden" />
                </FormControl>

                <FormControl margin="normal">
                    <Button variant="contained" onClick={handleSave}>Save Changes</Button>
                </FormControl> 

                </Grid>
           </Grid>
        </>
    )
}

export default ChainForm;