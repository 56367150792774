import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LinkIcon from '@mui/icons-material/Link';
import BuildIcon from '@mui/icons-material/Build';
import CategoryIcon from '@mui/icons-material/Category';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';

function AdminMenu({ openModal }) {
    const actions = [
        { icon: <LinkIcon />, name: 'New Chain', modal: 'setChainModalOpen' },
        { icon: <BuildIcon />, name: 'New Tool', modal: 'setToolModalOpen' },
        { icon: <CategoryIcon />, name: 'New Category', modal: 'setCategoryModalOpen' },
        { icon: <ListIcon />, name: 'New Type', modal: 'setTypeModalOpen' },
      ];

    return (
    <Box sx={{ flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon openIcon={<AddIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => openModal(action.modal)}
          />
        ))}
      </SpeedDial>

      {/* <Box mt={5}>
          <Button variant="contained" onClick={() => setChainModalOpen(true)}>Add Chain</Button>
          <Button variant="contained" onClick={() => setToolModalOpen(true)}>Add Tool</Button>
          <Button variant="contained" onClick={() => setCategoryModalOpen(true)}>Add Category</Button>
          <Button variant="contained" onClick={() => setTypeModalOpen(true)}>Add Type</Button>
        </Box> */}
    </Box>
  );
}

export default AdminMenu;